import { apiGet, filepost } from './index'

export function listDocument(data) {
	let url = "/api/Document/list/" + data;
	return apiGet(url);

}

export function getDocument(data) {
	let url = "/api/Document/read/" + data;
	return apiGet(url, {params: {timestamp: Math.random()}});
}

export function postDocument(data) {
	return filepost("/api/Document/edit", data);
}

export function addDocument(data) {
	return filepost("/api/Document/add", data);
}

export function deleteDocument(data) {
	return apiGet("/api/Document/delete/" + data);
}

export function download(data) {
	return apiGet("/api/Document/download/" + data, {
        responseType: 'blob',
      });
}

export function downloaPDF(data) {
	return apiGet("/api/Document/downloadPDF/" + data, {
        responseType: 'blob',
      });
}